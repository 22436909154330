<template>
  <section>
    <v-container fluid>
      <v-row>
        <v-col md="12">
          <PageHeader :title="headercontent.title" />
        </v-col>

        <v-col md="8">
          <v-form
            ref="form"
            v-model="valid"
            lazy-validation
            class="form-section"
          >
            <v-row>
              <v-col cols="12">
                <v-text-field
                  v-model="title"
                  outlined
                  label="Category Title"
                  class="mr-btm-minus"
                  :rules="titleRules"
                ></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field
                  v-model="sort"
                  outlined
                  label="Sort"
                  class="mr-btm-minus"
                  :rules="[sortRules.required, sortRules.nan]"
                ></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-select
                  v-model="status"
                  :items="statuses"
                  label="Status"
                  outlined
                  item-text="name"
                  item-value="status"
                ></v-select>
              </v-col>

              <v-col cols="12">
                <quill-editor
                  ref="myQuillEditor"
                  v-model="description"
                  :options="editorOption"
                  :rules="descriptionRules"
                />
              </v-col>
              <v-col cols="12">
                <image-uploader
                  :preview="true"
                  :className="['fileinput', { 'fileinput--loaded': hasImage }]"
                  capture="environment"
                  :debug="1"
                  doNotResize="gif"
                  :autoRotate="true"
                  outputFormat="file"
                  @input="setImage"
                  class="imagebox"
                >
                  <label for="fileInput" slot="upload-label">
                    <figure>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="32"
                        height="32"
                        viewBox="0 0 32 32"
                      >
                        <path
                          class="path1"
                          d="M9.5 19c0 3.59 2.91 6.5 6.5 6.5s6.5-2.91 6.5-6.5-2.91-6.5-6.5-6.5-6.5 2.91-6.5 6.5zM30 8h-7c-0.5-2-1-4-3-4h-8c-2 0-2.5 2-3 4h-7c-1.1 0-2 0.9-2 2v18c0 1.1 0.9 2 2 2h28c1.1 0 2-0.9 2-2v-18c0-1.1-0.9-2-2-2zM16 27.875c-4.902 0-8.875-3.973-8.875-8.875s3.973-8.875 8.875-8.875c4.902 0 8.875 3.973 8.875 8.875s-3.973 8.875-8.875 8.875zM30 14h-4v-2h4v2z"
                        ></path>
                      </svg>
                    </figure>
                    <span class="upload-caption">{{
                      hasImage ? "Replace" : "Click to upload"
                    }}</span>
                  </label>
                </image-uploader>
              </v-col>

              <v-col>
                <div class="rmimagebox">
                  <!-- <p>Previous Image:</p> -->
                  <img :src="imageUrl" alt="" class="uploadedImage" />
                </div>
              </v-col>
              <v-col cols="12" class="submitbtn">
                <v-btn
                  :disabled="!valid"
                  class="submitbutton btn-dark"
                  @click="validate"
       
                  :loading="submitLoading"
                >
                  {{ headercontent.title }}
                </v-btn>
              </v-col>
            </v-row>
          </v-form>
          <v-snackbar v-model="snackbar">
            {{ snackbarMessage }}
          </v-snackbar>
        </v-col>
      </v-row>
    </v-container>
  </section>
</template>

<script>
import PageHeader from "@/components/dashboard/PageHeader";
import ImageUploader from "vue-image-upload-resize";
import { mapActions, mapGetters } from "vuex";
export default {
  name: "AddCategory",
  components: { PageHeader, ImageUploader },
  data: () => ({
    valid: true,
    items: ["Foo", "Bar", "Fizz", "Buzz"],
    msg: "Vue Image Upload and Resize Demo",
    hasImage: false,
    image: null,
    title: "",
    description: "",
    sort: 0,
    headercontent: {
      title: "Add Category",
      addlink: "/dashboard/videoCategory/add",
      downloadlink: true,
    },
    content: "<h2>I am Example</h2>",
    editorOption: {
      // Some Quill options...
    },
    titleRules: [(v) => !!v || "Category Title is required"],
    statusRules: [(v) => !!v || "Status is required"],
    sortRules: {
      required: (value) => !!value || "Sort is required.",
      nan: (v) => !isNaN(v) || "Sort should be number",
    },
    descriptionRules: [(v) => !!v || "Description is required"],
    statuses: [
      { name: "Yes", status: true },
      { name: "No", status: false },
    ],
    status: true,
    imageUrl: null,
    id: null,
    submitLoading: false,
    snackbar: false,
    snackbarMessage: "",
  }),
  methods: {
    ...mapActions(["addCategory", "fetchCategoryBySlug", "updateCategory"]),
    async validate() {
      if (this.$refs.form.validate()) {
        this.submitLoading = true;
        let fd = new FormData();
        fd.append("title", this.title);
        fd.append("description", this.description);
        fd.append("sort", this.sort);
        fd.append("status", this.status);
        fd.append("categoryType", "video");
        if (this.$route.params.slug == undefined) {
          if (this.image != null) {
            fd.append("image", this.image);
            fd.append("fileType", "images");
            fd.append("imageFile", "categories");
            await this.addCategory(fd);
            this.snackbarMessage = "Category Added Successfully";
          } else {
            await this.addCategory(fd);
            this.snackbarMessage = "Category Added Successfully";
          }
        } else {
          if (this.image != null) {
            fd.append("id", this.id);
            fd.append("image", this.image);
            fd.append("fileType", "images");
            fd.append("imageFile", "categories");
            await this.updateCategory(fd);
            this.snackbarMessage = "Category Updated Successfully";
          } else {
            if (this.imageUrl == null) {
              fd.append("id", this.id);
              await this.updateCategory(fd);
              this.snackbarMessage = "Category Updated Successfully";
            } else {
              console.log(this.imageUrl);
              fd.append("id", this.id);

              await this.updateCategory(fd);
              this.snackbarMessage = "Category Updated Successfully";
            }
          }
        }
        this.submitLoading = false;
        this.$router.push("/dashboard/vidoesCategory");
      }
    },

    setImage: function (output) {
      this.hasImage = true;
      this.image = output;
      console.log("Info", output.info);
      console.log("Exif", output.exif);
    },

    onEditorChange({ quill, html, text }) {
      console.log(quill, text);
      this.content = html;
    },
    removeImage() {
      this.imageUrl = "";
    },
  },
  computed: {
    ...mapGetters(["allLoadingCurd", "allCategory"]),
    editor() {
      return this.$refs.myQuillEditor.quill;
    },
  },

  async created() {
    await this.fetchCategoryBySlug(this.$route.params.slug);
    if (this.$route.params.slug != undefined) {
      this.headercontent.title = "Edit Video Category";
      this.title = this.allCategory.title;
      this.description = this.allCategory.description;
      this.sort = this.allCategory.sort;
      this.status = this.allCategory.status;
      this.imageUrl = `https://app.necojobs.com.np${this.allCategory.imageUrl}`;
      this.id = this.allCategory._id;
    }
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/variables.scss";
.btn-dark {
  background: $tagcolor !important;
  color: $white;
  width: 200px;
  min-height: 50px;
}
.uploadedImage {
  width: 150px;
  padding: 20px;
}
.imagebox {
  display: flex;
  max-width: 30%;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
  padding: 20px;
  margin-top: 20px;
  label {
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
  }
}
.rmimagebox {
  width: 250px;
  line-height: 0.1;

  img {
    width: 100%;
    margin: -6px 0 0 -16px;
  }

  .v-icon {
    right: 0;
    top: 0;
  }
}
</style>
